import React, { useContext } from "react";
import "./BlueModernTemplate.css";
import QRCode from "qrcode.react";

const BlueModernTemplate = ({resumeData}) => {
  const {isQRCode, liveTempId } = resumeData;
  const liveTemplateURL = `http://localhost:3000/${liveTempId}`;
  const headingStyleCSS = {
    fontFamily: resumeData.headingTextFont,
    color: resumeData.headingTextColor
  }

  const paraStyleCSS = {
    fontFamily: resumeData.bodyTextFont,
    color: resumeData.bodyTextColor
  }

  return (
    <div className="MRT-Template">
      {
        isQRCode ? <div className="resume-qr-code">
        <QRCode value={liveTemplateURL} size={'50'} />
    </div> :
    <></>
      }
      <div className="MRT-Upper-section">
        
         

        <div className="MRT-Heading-Style MRT-Lower-section">
          <div className="MRT-Left">

          <div className="MRT-Sec-img" style={{
            backgroundImage: `url(${resumeData.profileImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}>
          </div>
   
            <h2 style={headingStyleCSS} className="hhh">{resumeData.AllSections[5].sectionName}</h2>
            {resumeData.AllSections[5].list.map((element, index) => {
              const {
                collegeName,
                course,
                startDate,
                endDate,
                aboutEducation,
              } = element;
              return (
                <div key={index}>
                  <h4 style={headingStyleCSS}>{startDate} - {endDate}<br />
                    {course}</h4>
                  <div className="MRT-mydiv MRT-Para-Size-Option">
                    <h4 style={headingStyleCSS}> {collegeName} </h4>
                    <p style={paraStyleCSS} className="para-font-size">{aboutEducation}</p>
                  </div>
                </div>
              );
            })}
    
            <div className="MRT-Heading-style MRT-skils">
              <h2 style={headingStyleCSS} className="hhh">{resumeData.AllSections[3].sectionName}</h2>
              <div className='MRT-skills-list'>
                {resumeData.AllSections[3].list.map((element, index) => (
                  <li style={paraStyleCSS} key={index}>{element.skillName}</li>
                ))}
              </div>
            </div>
          </div>
          <div className="MRT-Right">
          <div className="first-sec">
              <h1 style={headingStyleCSS}>{resumeData.userName}</h1>
              <p style={paraStyleCSS}>{resumeData.userJobRole}</p>
            </div>
         
            <div className="MRT-contact-details">
              {resumeData.AllSections[1].list.map((element, index) => (
                <div key={index} className="MRT-sec-details MRT-Para-Size-Option">
                  <i className={element.iconName}></i>
                  <p style={paraStyleCSS} className="para-font-size">{element.contactName}</p>
                </div>
              ))}
            </div>
            <div lang="MRT-sec-profile">
                <h2 style={headingStyleCSS} className="hhh custom-hhh">{resumeData.AllSections[0].sectionName}</h2>
                <p style={paraStyleCSS} className="ppp para-font-size">{resumeData.AllSections[0].summary}</p>
              </div>
         
            <h2 style={headingStyleCSS} className="hhh">{resumeData.AllSections[4].sectionName}</h2>
            {resumeData.AllSections[4].list.map((element, index) => {
              const { companyName, jobRole, startDate, endDate, aboutJob } = element;
              return (
                <div key={index}>
                  <h4 style={headingStyleCSS}>{startDate} - {endDate}</h4>
                  <div className="MRT-mydiv MRT-Para-Size-Option">
                    <h4 style={headingStyleCSS}><p style={paraStyleCSS}>{jobRole}-{companyName}</p></h4>
                    <p style={paraStyleCSS} className="para-font-size">{aboutJob}</p>
                  </div>
                </div>
              );
            })}
          
            <h2 style={headingStyleCSS} className="hhh">{resumeData.AllSections[2].sectionName}</h2>
            {resumeData.AllSections[2].list.map((element, index) => {
              const { projectName, startDate, endDate, aboutProject } = element;
              return (
                <div key={index}>
                  <h4 style={headingStyleCSS}>{startDate} - {endDate}</h4>
                  <div className="MRT-mydiv MRT-Para-Size-Option">
                    <h4 style={headingStyleCSS}>{projectName}</h4>
                    <p style={paraStyleCSS} className="para-font-size">{aboutProject}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlueModernTemplate;