import React, { useState } from 'react'
import GlobalContext from './GlobalContext'
import SaveAlert from '../alerts/SaveAlert';
import GetJob from "../../assets/blog-images/Get Job.jpg"

export default function GlobalState(props) {

    const [currentTemplateData, setCurrentTemplateData] = useState(JSON.parse(localStorage.getItem('currentTemplate')));
    const backServer = "https://node.careergennie.com";
    const GoogleClientId = "519773979023-1h0hk6ruh0uhkjqlmlug6stnl13hckje.apps.googleusercontent.com";
    const authtoken = JSON.parse(localStorage.getItem('authtoken'));
    const [isSaveData, setIsSaveData] = useState(<SaveAlert />);

    const [blogPosts, setBlogPosts] = useState([
        { id: 1, title: 'Tailor Your CV to the Job', date: 'September 10, 2024', excerpt: 'One size does not fit all when it comes to CVs. Tailoring your CV to each job application is essential.', image: GetJob, postPath: "tailor" },
    ]);


    // GLOBAL FUNCTIONS

    const DeleteItem = (index, id) => {
        console.log(currentTemplateData)
        setCurrentTemplateData((prevData) => {
            const newData = { ...prevData };
            newData.AllSections[index].list = newData.AllSections[index].list.filter(item => item.listId !== id);
            return newData;
        })
    }

    const AddItem = (index, objectData) => {
        try {
            setCurrentTemplateData((prevData) => {
                const newData = { ...prevData };
                newData.AllSections[index].list = [...newData.AllSections[index].list, objectData];
                return newData;
            });
        } catch (err) {
            console.log(err);
        }
    }

    const ChangeListValue = (event, index, id) => {
        const { value, name } = event.target;
        setCurrentTemplateData((prevData) => {
            const newData = { ...prevData };
            newData.AllSections[index].list = newData.AllSections[index].list.map(item => {
                if (item.listId === id) {
                    return { ...item, [name]: value };
                }
                return item;
            });
            return newData;
        });
    }

    const ChangeSectionValue = (event, index) => {
        setCurrentTemplateData((prevData) => {
            const updateContact = { ...prevData };
            updateContact.AllSections[index] = { ...updateContact.AllSections[index], [event.target.name]: event.target.value };
            return updateContact;
        })
    }

    const HideOverflowOnPop = (isHide) => {
        isHide ? document.body.classList.add("overflow-hidden") : document.body.classList.remove("overflow-hidden");
    }

    return (
        <GlobalContext.Provider value={{ currentTemplateData, setCurrentTemplateData, DeleteItem, AddItem, ChangeListValue, ChangeSectionValue, GoogleClientId, authtoken, isSaveData, setIsSaveData, backServer, blogPosts, setBlogPosts, HideOverflowOnPop }}>
            {props.children}
        </GlobalContext.Provider>
    )
}
