import React, { useContext } from 'react';
import GlobalContext from '../context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import '../Resume-process-css/summary.css';
import SaveAlert from '../alerts/SaveAlert';

export default function Summary() {

    const { currentTemplateData, ChangeSectionValue, authtoken, setIsSaveData, backServer } = useContext(GlobalContext);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!authtoken) {
                localStorage.setItem('currentTemplate', JSON.stringify(currentTemplateData));
                navigate('/edit-resume/contact-info');
                return;
            }

            await fetch(`${backServer}/dashboard/update-templates`, {
                method: "POST",
                body: JSON.stringify(currentTemplateData),
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': authtoken
                }
            })
            setIsSaveData(<SaveAlert status={"show"} alertMsg={"All changes saved"}/>);
            setTimeout(() => {
                setIsSaveData(<SaveAlert status={"hide"} alertMsg={"All changes saved"}/>);
            }, 800);
            //ALSO SAVE IN LOCAL STORAGE
            localStorage.setItem('currentTemplate', JSON.stringify(currentTemplateData));
            navigate('/edit-resume/contact-info');
        } catch (error) {
            console.log("Server error.", error);
        }
    };


    return (
        <div className='summary-form'>
            <h1>Summary</h1>
            <form action="" onSubmit={handleSubmit}>

                <div className="summary-form-name">
                    {/* <label><i class="fa-solid fa-circle-user"></i> About Me</label> */}
                    {/* <label> About Me</label> */}
                    <input type="text" name="sectionName" value={currentTemplateData.AllSections[0].sectionName} onChange={(e) => ChangeSectionValue(e, 0)} required placeholder='About me' />
                </div>
                {/* <label><i class="fa-solid fa-clipboard-list"></i> Summary</label> */}
                {/* <label>Summary</label> */}
                <div className="summary-form-textarea">
                    <textarea type="text" name="summary" value={currentTemplateData.AllSections[0].summary} onChange={(e) => ChangeSectionValue(e, 0)} required placeholder='summary' />
                </div>
                <div className="summary-next-button">
                    <button type='submit'>Save and Next</button>
                </div>

            </form>
        </div>
    )
}
