import React from 'react'
import './SalesAchieverResume.css';
import QRCode from 'qrcode.react';

export const SalesAchieverResume = ({ resumeData }) => {

    const {isQRCode, liveTempId } = resumeData;
    const liveTemplateURL = `http://localhost:3000/${liveTempId}`;
    const themStyle = { backgroundColor: resumeData.themeColor }
    const { backgroundPattern } = resumeData.resumeStyle;

    const headingStyleCSS = {
        fontFamily: resumeData.headingTextFont,
        color: resumeData.headingTextColor
    }

    const paraStyleCSS = {
        fontFamily: resumeData.bodyTextFont,
        color: resumeData.bodyTextColor,
        fontSize: `${resumeData.bodyTextSize}px`
    }

    const combineHeadingStyle = { ...headingStyleCSS, fontSize: `${resumeData.headingTextSize}px` }

    return (
        <div className="SalesAchieverRT" style={{ backgroundImage: `url("./background-pattern/${backgroundPattern}.png")` }}>
          
          {
                        isQRCode ? <div className="resume-qr-code">
                          <QRCode value={liveTemplateURL} size={"50"} />
                        </div> : <></>
                      }
            {/* Shapes */}
            <div className="tiangle-shapes"></div>
            {/* Shapes */}
            <div className="parent-sec">
                <div className="left-sec" style={themStyle}>
                    <div className='for-img' style={{ backgroundImage: `url(${resumeData.profileImage})` }}></div>
                    <div className="contact custom-heading-space">
                        <h2 style={combineHeadingStyle} >{resumeData.AllSections[1].sectionName}</h2>
                        {resumeData.AllSections[1].list.map((element, index) => (
                            <div className='contact-list' key={index} style={paraStyleCSS}>
                                <i class={element.iconName}></i>
                                <p style={paraStyleCSS} className="para-font-size">{element.contactName}</p>
                            </div>
                        ))}
                    </div>
                    <div className="skills custom-heading-space">
                        <h2 style={combineHeadingStyle} >{resumeData.AllSections[3].sectionName}</h2>
                        {resumeData.AllSections[3].list.map((element, index) => (
                            <li style={paraStyleCSS} key={index}>{element.skillName}</li>
                        ))}
                    </div>
                    <div className="education custom-heading-space">
                        <h2 style={combineHeadingStyle}>{resumeData.AllSections[5].sectionName}</h2>
                        {resumeData.AllSections[5].list.map((element, index) => {
                            const {
                                collegeName,
                                course, startDate, endDate, aboutEducation
                            } = element;
                            return (
                                <div key={index}>
                                    <p style={paraStyleCSS}><b>{course}</b></p>
                                    <p style={paraStyleCSS}><b>{collegeName} | {startDate} | {endDate}</b></p>
                                    <p style={paraStyleCSS}>{aboutEducation}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="right-sec">
                    <div className="profile-details">
                        <h1 style={headingStyleCSS} >{resumeData.userName}</h1>
                        <h4 style={headingStyleCSS}>{resumeData.userJobRole}</h4>
                    </div>
                    <div className="gradient-section" style={{ background: `linear-gradient(130deg, rgba(255,255,255,1) 30%, ${resumeData.themeColor} 30%)` }}>
                    </div>
                    <div className='for-spacing'>
                        <div className="summary custom-heading-space">
                            <h2 style={combineHeadingStyle} >{resumeData.AllSections[0].sectionName}</h2>
                            <p style={paraStyleCSS}>{resumeData.AllSections[0].summary}</p>
                        </div>
                        <div className="experience custom-heading-space">
                            <h2 style={combineHeadingStyle} >{resumeData.AllSections[4].sectionName}</h2>
                            {resumeData.AllSections[4].list.map((element, index) => {
                                const { companyName, jobRole, startDate, endDate, aboutJob } = element;
                                return (
                                    <div key={index}>
                                        <p style={paraStyleCSS}><b>{jobRole}</b></p>
                                        <p style={paraStyleCSS}><b>{companyName} | {startDate} - {endDate}</b></p>
                                        <p style={paraStyleCSS}>{aboutJob}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="projects custom-heading-space">
                            <h2 style={combineHeadingStyle} >{resumeData.AllSections[2].sectionName}</h2>
                            {resumeData.AllSections[2].list.map((element, index) => {
                                const { projectName, startDate, endDate, aboutProject } = element;
                                return (
                                    <div key={index}>
                                        <p style={paraStyleCSS}><b>{projectName}  |  {startDate}-{endDate}</b></p>
                                        <p style={paraStyleCSS}>{aboutProject}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
