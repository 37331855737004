import React, { useContext, useState } from "react";
import "../Resume-process-css/job-exp.css";
import GlobalContext from "../context/GlobalContext";
import ResumePreviewpopup from "../popup/ResumePreviewpopup";
import { TemplateData } from "../templates/TemplateData";

export default function JobExp() {
  const {
    currentTemplateData,
    DeleteItem,
    AddItem,
    ChangeListValue,
    ChangeSectionValue,
    authtoken,
    backServer
  } = useContext(GlobalContext);
  const index = 4;
  const { sectionName, list } = currentTemplateData.AllSections[index];

  const [isPreview, setIsPreview] = useState(false);

  const objectData = {
    listId: new Date().getTime().toString(),
    companyName: "Company Name",
    jobRole: "Your designation",
    startDate: "YY",
    endDate: "YY",
    aboutJob: "Description",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!authtoken) {
        localStorage.setItem(
          "currentTemplate",
          JSON.stringify(currentTemplateData)
        );
        return;
      }

      await fetch(`${backServer}/dashboard/update-templates`, {
        method: "POST",
        body: JSON.stringify(currentTemplateData),
        headers: {
          "Content-Type": "application/json",
          "auth-token": authtoken,
        },
      });
      //ALSO SAVE IN LOCAL STORAGE
      localStorage.setItem('currentTemplate', JSON.stringify(currentTemplateData));
    } catch (error) {
      console.log("Server error.", error);
    }
  };

  return (
    <>
      <div className="job-exp">
        <h1>Job Experience</h1>
        <form action="" onSubmit={handleSubmit}>
          <div className="job-exp-edit-section">
            {/* <label><i class="fa-solid fa-briefcase"></i> Job Experience</label> */}
            <input
              id="section-name"
              type="text"
              name="sectionName"
              value={sectionName}
              placeholder="Section Name"
              onChange={(e) => ChangeSectionValue(e, index)}
            />
          </div>
          {list.map((element) => {
            const {
              listId,
              companyName,
              jobRole,
              startDate,
              endDate,
              aboutJob,
            } = element;
            return (
              <div key={listId}>
                <div className="job-exp-datepic-monthdate-input">
                  <div className="job-exp-label-section">
                    {/* <label><i class="fa-solid fa-building"></i> Company Name</label> */}
                    <input
                      class="section"
                      type="text"
                      name="companyName"
                      value={companyName}
                      onChange={(e) => ChangeListValue(e, index, listId)}
                      placeholder="Company Name"
                    />
                  </div>
                  <div className="job-exp-label-section">
                    {/* <label><i class="fa-solid fa-building"></i> Job Role</label> */}
                    <input
                      class="section"
                      type="text"
                      name="jobRole"
                      value={jobRole}
                      onChange={(e) => ChangeListValue(e, index, listId)}
                      placeholder="Job Role"
                    />
                  </div>
                </div>
                <div className="job-exp-section-pic-date">
                  <div className="job-exp-datepic-monthdate">
                    {/* <label><i class="fa-solid fa-calendar-days"></i> Start Date</label> */}
                    <input
                      className="select-option"
                      type="date"
                      value={startDate}
                      onChange={(e) => ChangeListValue(e, index, listId)}
                      name="startDate"
                    />
                  </div>
                  <div className="job-exp-datepic">
                    {/* <label><i class="fa-solid fa-calendar-days"></i> End Date</label> */}
                    <input
                      className="select-option"
                      type="date"
                      value={endDate}
                      onChange={(e) => ChangeListValue(e, index, listId)}
                      name="endDate"
                    />
                  </div>
                </div>
                <div className="textarea-delete-section">
                  {/* <label><i class="fa-solid fa-pen-to-square"></i> Job Details</label> */}
                  <textarea
                    type="text"
                    name="aboutJob"
                    value={aboutJob}
                    onChange={(e) => ChangeListValue(e, index, listId)}
                    placeholder="Job Details"
                  />
                </div>

                <div className="job-exp-del-add-button">
                  <hr />
                  <i
                    className="fa-solid fa-trash"
                    onClick={() => DeleteItem(index, listId)}
                  ></i>
                </div>
              </div>
            );
          })}
          <div className="job-exp-add">
            <a href="#" onClick={() => AddItem(index, objectData)}>
              {" "}
              <i className="fa-solid fa-plus"></i> Add Section
            </a>
          </div>
          <div
            className="job-next-button"
            onClick={() =>
              setIsPreview(
                <ResumePreviewpopup
                  element={TemplateData.map((element) => {
                    if (element.id === currentTemplateData.id)
                      return (
                        <element.element resumeData={currentTemplateData} />
                      );
                  })}
                  cancel={() => setIsPreview(false)}
                />
              )
            }
          >
            <button type="submit">Save and Preview</button>
          </div>
        </form>
      </div>
      {isPreview}
    </>
  );
}