import React from 'react'
import './GrayThemetemplate.css';
import QRCode from 'qrcode.react';


export const GrayThemeTemplate = ({ resumeData }) => {
  const {isQRCode, liveTempId } = resumeData;
  const liveTemplateURL = `http://localhost:3000/${liveTempId}`;
  const headingStyleCSS = {
    fontFamily: resumeData.headingTextFont,
    // color: resumeData.headingTextColor
  }
  const { backgroundPattern } = resumeData.resumeStyle;

  const paraStyleCSS = {
    fontFamily: resumeData.bodyTextFont,
    // color: resumeData.bodyTextColor,
    fontSize: `${resumeData.bodyTextSize}px`
  }

  const combineHeadingStyle = { ...headingStyleCSS, fontSize: `${resumeData.headingTextSize}px` }

  return (

    <div className="GTT-resume-template" style={{backgroundImage: `url("./background-pattern/${backgroundPattern}.png")`}}>
      {
                        isQRCode ? <div className="resume-qr-code">
                          <QRCode value={liveTemplateURL} size={"50"} />
                        </div> : <></>
                      }
      
      {/*Name SO-1 */}
      <div className="GTT-template  name-sec-so-1">
        <img src={resumeData.profileImage} alt="" style={{ width: '120px', height: '120px' }} />
        <div className='name-sec-so-1-details'>
          <h1 style={headingStyleCSS}>{resumeData.userName}</h1>
          <h4 style={headingStyleCSS}>{resumeData.userJobRole}</h4>
        </div>
      </div>

      {/*Contact SO-2 */}
      <div className='contact-sec-so2'>
        {resumeData.AllSections[1].list.map((element) => {
          return (
            <div style={paraStyleCSS} class="account-so-2-details">
              <i class={element.iconName}></i>
              <p>{element.contactName}</p>
            </div>
          );
        })}
      </div>

      {/* Profile SO-3 */}
      <div className="gtt-for-heading profile-so-3">
        <div className='gtt-for-heading profile-so-3-a'>
          <h2 style={combineHeadingStyle}>{resumeData.AllSections[0].sectionName}</h2>
        </div>
        <p style={paraStyleCSS}>{resumeData.AllSections[0].summary}</p>
      </div>
      

      {/* GROUP SO-4 */}
      <div className="grp-so-4">
        <div className="so-4-left">

          {/* experience | Job */}
          <div className=" gtt-for-heading gtt-job-section">

            <h2 style={combineHeadingStyle}>{resumeData.AllSections[4].sectionName}</h2>
            <div className='gtt-job-secction-details'>
              {/* <hr className='gtt-forhr' /> */}
              {resumeData.AllSections[4].list.map((element, index) => {
                const { companyName, jobRole, startDate, endDate, aboutJob } =
                  element;
                return (
                  <div key={index}>
                    <p  style={{ ...paraStyleCSS, fontWeight: 700 }}>{companyName} | {startDate} - {endDate} </p>
                    <p id='for-bottom-spacing' style={{...paraStyleCSS, fontWeight: 700}} className='gtt-sub-heading'>{jobRole}</p>
                    <p className='fa-description' style={paraStyleCSS}>{aboutJob}</p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* projects */}
          <div className="gtt-for-heading gtt-projects">
            <h2 style={combineHeadingStyle}>{resumeData.AllSections[2].sectionName}</h2>
            <div className="gtt-projeccts-details">
              {resumeData.AllSections[2].list.map((element, index) => {
                const { projectName, startDate, endDate, aboutProject } =
                  element;
                return (
                  <div key={index}>
                     <p id='for-bottom-spacing' style={{ ...paraStyleCSS, fontWeight: 700 }}>{projectName} |  {startDate} - {endDate}</p>
                    <p className='fa-description' style={paraStyleCSS}>{aboutProject}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>


        <div className="so-4-right">
          {/* Education */}
          <div className='gtt-for-heading gtt-education'>
            <h2 style={combineHeadingStyle}>{resumeData.AllSections[5].sectionName} </h2>
            <div className='gtt-education-details'>
              {resumeData.AllSections[5].list.map(
                (element, index) => {
                  const {
                    collegeName,
                    course,
                    startDate,
                    endDate,
                    aboutEducation,
                  } = element;
                  return (
                    <React.Fragment key={index}>
                        <p  style={{ ...paraStyleCSS, fontWeight: 700}}>{collegeName} |  {startDate} - {endDate}</p>
                      <p id='for-bottom-spacing' style={{...paraStyleCSS, fontWeight: 700}} className='gtt-sub-heading'>{course}</p>
                      <p className='fa-description' style={paraStyleCSS}>{aboutEducation}</p>
                    </React.Fragment>
                  );
                }
              )}
            </div>
          </div>

          {/* Skills */}
          <div className="gtt-for-heading gtt-skils">
            <h2 style={combineHeadingStyle}>{resumeData.AllSections[3].sectionName}</h2>
            <div className='gtt-skills-list'>
              {resumeData.AllSections[3].list.map((element) => {
                return <li style={paraStyleCSS}>{element.skillName}</li>

              })}
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}

