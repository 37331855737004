import React, { useContext, useState } from 'react';
import './ResumeDashboard.css';
import GlobalContext from '../context/GlobalContext';
import { TemplateData } from '../templates/TemplateData';
import ResumePreviewpopup from '../popup/ResumePreviewpopup';
// import QRCode from 'qrcode.react';

export default function ResumeDashboard() {

    const { currentTemplateData } = useContext(GlobalContext);
    const [isPreview, setIsPreview] = useState(false);

    return (
        <>
            <div className="prev-wrapper">
                <div className="resume-prev-section">
                    <div className='resumeCV'>
                        {TemplateData.map((element) => {
                            if (element.id === currentTemplateData.id) {
                                return <element.element resumeData={currentTemplateData} />;
                            }
                            return <></>
                        })}
                    </div>


                    {/* PREVIEW BUTTON */}

                    {isPreview ? <></> :
                        <div className="resumecv-eye" onClick={() => setIsPreview(true)}>
                            <i className='fa-regular fa-eye'></i>
                        </div>
                    }
                </div>
            </div>

            {isPreview ? <ResumePreviewpopup element={TemplateData.map((element) => {
                if (element.id === currentTemplateData.id) return <element.element resumeData={currentTemplateData} />;
            })} cancel={() => setIsPreview(false)} /> : <></>}
        </>
    )
}