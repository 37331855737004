import React, { useEffect, useRef } from 'react';
import './ResumePreviewPopup.css';
import { useReactToPrint } from 'react-to-print';

export default function ResumePreviewpopup({ cancel, element, useTemp, funcUseTemp }) {

    const FinalResumeRef = useRef();
    const NotCloseDownload = useRef();

    const ClosePopup = (e) => {
        if (FinalResumeRef.current.contains(e.target) || NotCloseDownload.current.contains(e.target)) {
            return;
        }
        cancel();
    };

    useEffect(() => {
        document.addEventListener("click", ClosePopup, true);

        // const { current } = FinalResumeRef;
        console.log(FinalResumeRef);
        return () => {
            document.removeEventListener("click", ClosePopup, true);
        };
    }, []);


    const DownloadResume = useReactToPrint({
        content: () => FinalResumeRef.current,
        bodyClass: "print-resume-page"
    });

    return (
        <div className="resume-prev-popup-body">
            <div className="prev-final-resume">
                <div className="final-resume" ref={FinalResumeRef}>{element}</div>
                <i className='fa-solid fa-xmark cancel-popup' onClick={cancel} ></i>
            </div>

            {
                useTemp ?
                    <button className='d-btn' ref={NotCloseDownload} onClick={funcUseTemp}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            className="injected-svg"
                            data-src="https://cdn.hugeicons.com/icons/pencil-edit-01-stroke-standard.svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            role="img"
                            color="#fff"
                        >
                            <path
                                d="M14 7L5.39171 15.6083C5.1354 15.8646 4.95356 16.1858 4.86564 16.5374L4 20L7.46257 19.1344C7.81424 19.0464 8.1354 18.8646 8.39171 18.6083L17 10M14 7L16.2929 4.70711C16.6834 4.31658 17.3166 4.31658 17.7071 4.70711L19.2929 6.29289C19.6834 6.68342 19.6834 7.31658 19.2929 7.70711L17 10M14 7L17 10"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M11.5 20H17.5"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                        <span>Use Template</span>
                    </button>
                    :
                    <button className='d-btn' ref={NotCloseDownload} onClick={DownloadResume}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            className="injected-svg"
                            data-src="https://cdn.hugeicons.com/icons/download-04-solid-rounded.svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            role="img"
                            color="#fff"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M13.0059 4.4998C13.0059 3.94752 12.5581 3.4998 12.0059 3.4998C11.4536 3.4998 11.0059 3.94752 11.0059 4.4998L11.0059 10.9998L10.4116 10.9998C10.236 10.9997 10.0203 10.9995 9.84387 11.0216L9.84053 11.022C9.71408 11.0378 9.13804 11.1096 8.86368 11.6752C8.58872 12.2421 8.89065 12.7422 8.95597 12.8504L8.95841 12.8544C9.05062 13.0075 9.18477 13.1783 9.29511 13.3189L9.31885 13.3491C9.61348 13.725 9.99545 14.2092 10.3759 14.6002C10.5657 14.7953 10.783 14.9965 11.0139 15.1554C11.2191 15.2966 11.5693 15.4998 12 15.4998C12.4307 15.4998 12.7809 15.2966 12.9861 15.1554C13.217 14.9965 13.4343 14.7953 13.6241 14.6002C14.0046 14.2092 14.3865 13.725 14.6812 13.3491L14.7049 13.3189C14.8152 13.1783 14.9494 13.0075 15.0416 12.8544L15.044 12.8504C15.1093 12.7422 15.4113 12.2421 15.1363 11.6752C14.862 11.1096 14.2859 11.0378 14.1595 11.022L14.1561 11.0216C13.9797 10.9995 13.764 10.9997 13.5884 10.9998L13.0059 10.9998L13.0059 4.4998Z"
                                fill="currentColor"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4 15.5C4.55228 15.5 5 15.9477 5 16.5C5 17.0989 5.03256 17.5106 5.09643 17.8013C5.15781 18.0807 5.23372 18.181 5.27636 18.2236C5.31899 18.2663 5.41933 18.3422 5.69872 18.4036C5.98944 18.4674 6.40114 18.5 7 18.5H17C17.5989 18.5 18.0106 18.4674 18.3013 18.4036C18.5807 18.3422 18.681 18.2663 18.7236 18.2236C18.7663 18.181 18.8422 18.0807 18.9036 17.8013C18.9674 17.5106 19 17.0989 19 16.5C19 15.9477 19.4477 15.5 20 15.5C20.5523 15.5 21 15.9477 21 16.5C21 17.1421 20.9678 17.7259 20.857 18.2304C20.7437 18.7462 20.535 19.2407 20.1379 19.6379C19.7407 20.035 19.2462 20.2437 18.7304 20.357C18.2259 20.4678 17.6421 20.5 17 20.5H7C6.35786 20.5 5.77406 20.4678 5.26959 20.357C4.7538 20.2437 4.25926 20.035 3.86214 19.6379C3.46503 19.2407 3.25632 18.7462 3.14301 18.2304C3.03219 17.7259 3 17.1421 3 16.5C3 15.9477 3.44772 15.5 4 15.5Z"
                                fill="currentColor"
                            />
                        </svg>
                        <span>Download</span>
                    </button>
            }
        </div>
    )
}