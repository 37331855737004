import React from "react";
import "./FThemeTemplate.css";
import QRCode from "qrcode.react";

export const FThemeTemplate = ({ resumeData }) => {
  const {isQRCode, liveTempId } = resumeData;
  const liveTemplateURL = `http://localhost:3000/${liveTempId}`;

  const headingStyleCSS = {
    fontFamily: resumeData.headingTextFont,
    color: resumeData.headingTextColor
  }
  const { backgroundPattern } = resumeData.resumeStyle;

  const paraStyleCSS = {
    fontFamily: resumeData.bodyTextFont,
    color: resumeData.bodyTextColor,
    fontSize: `${resumeData.bodyTextSize}px`
  }

  const combineHeadingStyle = { ...headingStyleCSS, fontSize: `${resumeData.headingTextSize}px` }

  return (
    <div className="FThemeTemplate-Template" style={{backgroundImage: `url("./background-pattern/${backgroundPattern}.png")`}}>
      {
                        isQRCode ? <div className="resume-qr-code">
                          <QRCode value={liveTemplateURL} size={"50"} />
                        </div> : <></>
                      }
      <div className="FThemeTemplate-for-bottom-border-only">
        {/* Section-A */}
        <div className="FThemeTemplate-Section-A">
          <div className="FThemeTemplate-sub-section-A">
            <h1 style={headingStyleCSS}>{resumeData.userName}</h1>
            <h4 style={headingStyleCSS}>{resumeData.userJobRole}</h4>
          </div>
          <div className="FThemeTemplate-sec-acc-FT">
            {resumeData.AllSections[1].list.map((element) => {
              return (
                <div style={paraStyleCSS} className="FThemeTemplate-account" key={element.contactName}>
                  <p style={paraStyleCSS}>{element.contactName}</p>
                  <i className={element.iconName}></i>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="FThemeTemplate-padding">
        {/* Section-B */}
        <div className="FThemeTemplate-section-B">
          <div className="FThemeTemplate-for-heading FThemeTemplate-sub-section-B">
            <h2 style={combineHeadingStyle}>{resumeData.AllSections[0].sectionName}</h2>
          </div>
          <p style={paraStyleCSS}>{resumeData.AllSections[0].summary}</p>
        </div>
        {/* Section-C */}
        <div className="FThemeTemplate-section-C">
          <div className="FThemeTemplate-sub-section-c-left">
            {/* experience | Job */}
            <div className="FThemeTemplate-for-heading FThemeTemplate-sub-section-c-job">
              <h2 style={combineHeadingStyle}>{resumeData.AllSections[4].sectionName}</h2>
              <div className='FThemeTemplate-job-secction-details'>
                {resumeData.AllSections[4].list.map((element, index) => {
                  const { companyName, jobRole, startDate, endDate, aboutJob } = element;
                  return (
                    <div key={index}>
                    <p style={{ ...headingStyleCSS, fontWeight: 600 }}>{companyName} | {startDate} - {endDate} </p>
                      <p style={paraStyleCSS} className='FThemeTemplate-sub-heading'>{jobRole}</p>
                      <p style={paraStyleCSS}>{aboutJob}</p>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* projects */}
            <div className="FThemeTemplate-for-heading FThemeTemplate-projects">
              <h2 style={combineHeadingStyle}>{resumeData.AllSections[2].sectionName}</h2>
              <div className="FThemeTemplate-projeccts-details">
                {resumeData.AllSections[2].list.map((element, index) => {
                  const { projectName, startDate, endDate, aboutProject } = element;
                  return (
                    <div key={index}>
                <p style={{ ...headingStyleCSS, fontWeight: 600 }}>{projectName} | {startDate} - {endDate} </p>
                      <p style={paraStyleCSS}>{aboutProject}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="FThemeTemplate-sub-section-c-right">
            {/* Skills */}
            <div className="FThemeTemplate-for-heading FThemeTemplate-skils">
              <h2 style={combineHeadingStyle}>{resumeData.AllSections[3].sectionName}</h2>
              <div className='FThemeTemplate-skills-list'>
                {resumeData.AllSections[3].list.map((element, index) => (
                  <li style={paraStyleCSS} key={index}>{element.skillName}</li>
                ))}
              </div>
            </div>

            {/* Education */}
            <div className='FThemeTemplate-for-heading FThemeTemplate-education'>
              <h2 style={combineHeadingStyle}>{resumeData.AllSections[5].sectionName}</h2>
              <div className='FThemeTemplate-education-details'>
                {resumeData.AllSections[5].list.map((element, index) => {
                  const { collegeName, course, startDate, endDate, aboutEducation } = element;
                  return (
                    <React.Fragment key={index}>
                      <p style={{ ...headingStyleCSS, fontWeight: 600 }}>{collegeName} | {startDate} - {endDate} </p>
                      <p style={paraStyleCSS} className='FThemeTemplate-sub-heading'>{course}</p>
                      <p style={paraStyleCSS}>{aboutEducation}</p>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
