import React, { useEffect, useState } from 'react';
import './CvNestFooter.css';
import { Link, useLocation } from 'react-router-dom';

function CvNestFooter() {

    const [showFooter, setShowFooter] = useState(true);
    const location = useLocation();

    useEffect(() => {
        // Hide the footer on dashboard
        if (location.pathname.startsWith('/edit-resume'))
            setShowFooter(false);
    }, [location.pathname]);

    return (
        <div className="cvnest-social-media-icon" style={showFooter ? { display: 'flex' } : { display: 'none' }}>
            <div className='cvnest-footer-section'>
                <div className="logo-cvnest">
                    <h2 onClick={() => window.location.href = "/"} className="logo">CAREER <span style={{ fontWeight: '700', color: '#5A4ABD' }}>Gennie</span></h2>
                    <p> CV Nest is an online platform that simplifies creating professional resumes. It provides templates, tools, and guidance to help users craft tailored CVs aligned with their career goals</p>
                </div>

                <div className="quick-links">
                    <h3>Quick Links</h3>
                    <ol>
                        <li><Link to={"/templates"}>Template</Link></li>
                        <li><Link to={"/about"}>About</Link></li>
                        <li><Link to={"/blog"}>Blog</Link></li>
                        <li><Link to={"/contact"}>Contact</Link></li>
                    </ol>
                </div>
                <div className="contact-section">
                    <h3>Contact Info</h3>
                    <div className="contact-text-email-section">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="none"
                            className="injected-svg"
                            data-src="https://cdn.hugeicons.com/icons/mail-search-02-solid-standard.svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            role="img"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1 4.75C1 3.23122 2.23122 2 3.75 2H19.75C21.2688 2 22.5 3.23122 22.5 4.75V13.2688C21.4588 11.4644 19.5095 10.25 17.2765 10.25C13.9482 10.25 11.25 12.9482 11.25 16.2765C11.25 17.4621 11.5924 18.5678 12.1836 19.5H3.75C2.23122 19.5 1 18.2688 1 16.75V4.75ZM5.10229 6.37222C5.311 6.01443 5.77024 5.89358 6.12803 6.10229L11.1203 9.01444C11.5095 9.24147 11.9908 9.24147 12.38 9.01444L17.3722 6.10229C17.73 5.89358 18.1892 6.01443 18.398 6.37222C18.6067 6.73001 18.4858 7.18925 18.128 7.39796L13.1358 10.3101C12.2795 10.8096 11.2207 10.8096 10.3645 10.3101L5.37222 7.39796C5.01443 7.18925 4.89358 6.73001 5.10229 6.37222Z"
                                fill="currentColor"
                            />
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M12.75 16.2765C12.75 13.7766 14.7766 11.75 17.2765 11.75C19.7765 11.75 21.8031 13.7766 21.8031 16.2765C21.8031 17.1656 21.5468 17.9947 21.104 18.6942L22.456 20.0418C22.8471 20.4317 22.8481 21.0648 22.4582 21.456C22.0683 21.8471 21.4352 21.8481 21.044 21.4582L19.6888 20.1074C18.9905 20.5481 18.1633 20.8031 17.2765 20.8031C14.7766 20.8031 12.75 18.7765 12.75 16.2765ZM17.2765 13.75C15.8812 13.75 14.75 14.8812 14.75 16.2765C14.75 17.6719 15.8812 18.8031 17.2765 18.8031C18.6719 18.8031 19.8031 17.6719 19.8031 16.2765C19.8031 14.8812 18.6719 13.75 17.2765 13.75Z"
                                fill="currentColor"
                            />
                        </svg>

                        <div className="email-text">
                            <p>Email</p>
                            <span>abc@gmail.com</span>
                        </div>
                    </div>
                    <div className="Subscribe_Bar">
                        <input type="text" placeholder="your@gmail.com" />
                        <button>Subscribe</button>
                    </div>
                </div>
            </div>
            <div className="bottom-row">
                <div className='copyright-section'>
                    <p>© CareerGennie All rights reserved.</p>
                    <div className="policy">
                        <p>Privacy Policy</p>
                        <p>Terms of Service</p>
                    </div>
                </div>
                <div className="fb-insta-tele-section">
                    <p>Developed By <a href="https://upbstechnology.com" style={{ color: '#de4e26' }}>UpbsTechnology</a></p>
                    <p>|</p>
                    <i class="fa-brands fa-facebook-f"></i>
                    <i class="fa-brands fa-instagram"></i>
                    <i class="fa-brands fa-linkedin-in"></i>
                </div>
            </div>
        </div>
    )
}

export default CvNestFooter
