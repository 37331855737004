import React from "react";
import "./BeigeTemplate.css";
import QRCode from "qrcode.react";

const BeigeTemplate = ({ resumeData }) => {
  const {isQRCode, liveTempId} = resumeData;
  const liveTemplateURL = `http://localhost:3000/${liveTempId}`;

  const themeStyle = { backgroundColor: resumeData.themeColor }

  const headingStyleCSS = {
    fontFamily: resumeData.headingTextFont,
    // color: resumeData.headingTextColor,
  }
  const { backgroundPattern } = resumeData.resumeStyle;

  const paraStyleCSS = {
    fontFamily: resumeData.bodyTextFont,
    // color: resumeData.bodyTextColor,
    fontSize: `${resumeData.bodyTextSize}px`
  }

  const combineHeadingStyle = { ...headingStyleCSS, fontSize: `${resumeData.headingTextSize}px` }

  return (
    <div className="Beige-resume-template" style={{ backgroundImage: `url("./background-pattern/${backgroundPattern}.png")` }}>
      {
        isQRCode ? <div className="resume-qr-code">
          <QRCode value={liveTemplateURL} size={"50"} />
        </div> : <></>
      }
      <div className="Beige-a-Template">
        <div className="Beige-section Beige-name">
          <div className="Beige-name-data">
            <h1 style={headingStyleCSS}>{resumeData.userName}</h1>
            <h4 style={headingStyleCSS}>{resumeData.userJobRole}</h4>
          </div>
        </div>
        {/* <img className="design2" src={design2} alt="" style={{width:"50px" ,height:"50px"}} /> */}
        {/* <!--CONTACT SECTION--> */}
        <div className="Beige-section Beige-contact-me">
          <div className="Beige-contact-sec forHeading-beige">
            <h2 style={combineHeadingStyle}>{resumeData.AllSections[1].sectionName}</h2>
            {resumeData.AllSections[1].list.map((element) => {
              return (
                <div class="Beige-contact">
                  <i class={element.iconName}></i>
                  <p style={paraStyleCSS}>{element.contactName}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* <!--ABOUT SECTION--> */}
      <div className="Beige-about-blank-div">
        <div className="Beige-section Beige-about-me " style={themeStyle}>
          <div style={{ paddingLeft: '1rem' }}>
            <h2 style={combineHeadingStyle}>{resumeData.AllSections[0].sectionName}</h2>
            <p style={paraStyleCSS}>{resumeData.AllSections[0].summary}</p>
          </div>
          <div className="Beige-profile-image">
            <img src={resumeData.profileImage} alt="" />
          </div>
        </div>
        <svg
          style={{ position: 'absolute' }}
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width={64}
          zoomAndPan="magnify"
          viewBox="0 0 384 383.999986"
          height={64}
          preserveAspectRatio="xMidYMid meet"
          version={1.0}
        >
          <defs>
            <clipPath id="b7e1524858">
              <path
                d="M 0.0703125 0 L 366 0 L 366 356.25 L 0.0703125 356.25 Z M 0.0703125 0 "
                clipRule="nonzero"
              />
            </clipPath>
          </defs>
          <g clipPath="url(#b7e1524858)">
            <path
              fill={resumeData.themeColor}
              d="M 365.78125 0 C 167.628906 37.058594 39.257812 176.042969 0.320312 356.273438 L 0.320312 0 Z M 365.78125 0 "
              fillOpacity={1}
              fillRule="evenodd"
            />
          </g>
        </svg>
      </div>


      {/* <!--EDUCATION SECTION--> */}

      <div className="Beige-b-Template">
        <div className="Beige-lower-left">
          {/* <!--JOB SECTION--> */}
          <div className="Beige-section-job">
            <div className="Beinge-sec-job-exp">
              <div className="Beige-sec-job-heading forHeading-beige">
                <h2 style={combineHeadingStyle}>{resumeData.AllSections[4].sectionName}</h2>
              </div>
              <div className="Beige-sec-job-detail">
                {resumeData.AllSections[4].list.map((element, index) => {
                  const { companyName, jobRole, startDate, endDate, aboutJob } =
                    element;
                  return (
                    <div className="Beige-job-position" key={index}>
                      <div className="Beige-edu-grp">
                        <p style={{ ...paraStyleCSS, fontWeight: 600 }}>{companyName} </p>
                        <p style={paraStyleCSS} className="Beige-job-period">
                          {startDate} - {endDate}
                        </p>
                      </div>
                      <p style={paraStyleCSS} className="beige-role-name">{jobRole}</p>
                      <p style={paraStyleCSS} className="beige-about-job">{aboutJob}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* <!--PROJECT SECTION--> */}

          <div className="Beige-section Beige-project forHeading-beige">
            <h2 style={combineHeadingStyle}>{resumeData.AllSections[2].sectionName}</h2>
            <div className="Beige-projects ">
              {resumeData.AllSections[2].list.map((element, index) => {
                const { projectName, startDate, endDate, aboutProject } =
                  element;
                return (
                  <div className="Beige-section-position" key={index}>
                    <div className="Beige-edu-grp">
                      <p style={{ ...paraStyleCSS, fontWeight: 600 }}>{projectName} </p>
                      <p style={paraStyleCSS} className="Beige-job-period">
                        {startDate} - {endDate}
                      </p>
                    </div>
                    <p style={paraStyleCSS} className="beige-about-job">{aboutProject}</p>
                  </div>

                );
              })}
            </div>
          </div>
        </div>

        <div className="new-section-edu">
          <div className="Beige-section-education">
            <div className="Beige-section-edu">
              <div className="Beige-section-edu-heading forHeading-beige">
                <h2 style={combineHeadingStyle}>{resumeData.AllSections[5].sectionName}</h2>
              </div>
              <div className="Beige-section-edu-grp">
                {resumeData.AllSections[5].list.map(
                  (element, index) => {
                    const {
                      collegeName,
                      course,
                      startDate,
                      endDate,
                      aboutEducation,
                    } = element;
                    return (
                      <React.Fragment key={index}>
                        <div className="Beige-edu-grp">
                          <p style={{ ...paraStyleCSS, fontWeight: 600 }}>{collegeName} </p>
                          <p style={paraStyleCSS}>
                            {startDate} - {endDate}
                          </p>
                        </div>
                        <p style={paraStyleCSS} className="beige-course-name">{course}</p>
                        <p style={paraStyleCSS} className="beige-about-edu">{aboutEducation}</p>
                      </React.Fragment>
                    );
                  }
                )}
              </div>
            </div>

            {/* <!--SKILL SECTION--> */}

            <div className="Beige-skill-sec forHeading-beige">
              <h2 style={combineHeadingStyle}>{resumeData.AllSections[3].sectionName}</h2>
              <ul className="Beige-skills-sec">
                {resumeData.AllSections[3].list.map((element) => {
                  return <li style={paraStyleCSS}>{element.skillName}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
};


export default BeigeTemplate; 