import React from 'react'
import "./Yellow_Theme_Template.css";
import QRCode from 'qrcode.react';

export default function Yellow_Theme__Template({ resumeData }) {

  const { AllSections, isQRCode, liveTempId  } = resumeData;
  const liveTemplateURL = `http://localhost:3000/${liveTempId}`;
  const { themeColor } = resumeData;
  const { backgroundPattern } = resumeData.resumeStyle;

  const headingStyleCSS = {
    fontFamily: resumeData.headingTextFont,
    color: resumeData.headingTextColor,
  }

  const paraStyleCSS = {
    fontFamily: resumeData.bodyTextFont,
    color: resumeData.bodyTextColor,
    fontSize: `${resumeData.bodyTextSize}px`
  }

  const combineHeadingStyle = { ...headingStyleCSS, fontSize: `${resumeData.headingTextSize}px` }
  return (
    <div className="Yellow-Theme-Template" style={{ backgroundImage: `url(${backgroundPattern})` }}>
      {
        isQRCode ? <div className="resume-qr-code">
          <QRCode value={liveTemplateURL} size={"50"} />
        </div> : <></>
      }
      <div className="Header">
        <div className="Personal-Details" style={{ backgroundColor: themeColor }}>
          <h1 style={headingStyleCSS}>
            {resumeData.userName}<span style={{ color: "black", paddingLeft: "15%" }}></span>
          </h1>
        </div>
        <h4 style={headingStyleCSS}>
          {resumeData.userJobRole}
          <br />
        </h4>
      </div>
      <div className="Main">
        <div className="Left-Column">
          <div className="Summary-Information">
            <h2 style={combineHeadingStyle}>{AllSections[0].sectionName}<span className='designing-line' style={{ backgroundColor: themeColor }}></span></h2>
            <p style={paraStyleCSS}>{AllSections[0].summary}</p>
          </div>
          <div className="Skills-Information">
            <h2 style={combineHeadingStyle}>{AllSections[3].sectionName}<span className='designing-line' style={{ backgroundColor: themeColor }}></span></h2>
            <ul style={{ borderColor: themeColor }}>
              {AllSections[3].list.map(skill => {
                const { listId, skillName } = skill;
                return (
                  <li style={{ backgroundColor: themeColor }} key={listId}>
                    <p style={paraStyleCSS}>{skillName}</p>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="Project-Information">
            <h2 style={combineHeadingStyle}>{AllSections[2].sectionName}<span className='designing-line' style={{ backgroundColor: themeColor }}></span></h2>
            {AllSections[2].list.map(Project => {
              const { listId, projectName, startDate, endDate, aboutProject } = Project;
              return (
                <div className="Project-Details" key={listId}>
                  <p style={paraStyleCSS}>
                    <i>{startDate} - {endDate}</i>
                  </p>
                  <div>
                    <p style={paraStyleCSS}>
                      <b>{projectName}</b>
                    </p>
                    <p style={paraStyleCSS}>
                      {aboutProject}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="Right-Column">
          <div className="Contact-Information">
            {/* <h2>Contact</h2> */}
            <ul>
              {AllSections[1].list.map(element => {
                const { listId, iconName, contactName } = element
                return (
                  <li style={paraStyleCSS} key={listId}>
                    <i style={{ backgroundColor: themeColor }} className={iconName} />
                    {contactName}
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="Education-Information">
            <h2 style={combineHeadingStyle}>{AllSections[5].sectionName}<span className='designing-line' style={{ backgroundColor: themeColor }}></span></h2>
            {AllSections[5].list.map(Education => {
              const { listId, collegeName, course, startDate, endDate, aboutEducation } = Education;
              return (
                <div className="Education-Details" key={listId}>
                  <p style={paraStyleCSS}>
                    <i>{startDate} - {endDate}</i>
                  </p>
                  <div>
                    <p style={paraStyleCSS}>
                      <b>{course}</b>
                    </p>
                    <p style={paraStyleCSS}>
                      <b>{collegeName}</b>
                    </p>
                    <p style={paraStyleCSS}>
                      {aboutEducation}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="Experience-Information">
            <h2 style={combineHeadingStyle}>{AllSections[4].sectionName}<span className='designing-line' style={{ backgroundColor: themeColor }}></span></h2>
            {AllSections[4].list.map(Experience => {
              const { listId, companyName, jobRole, startDate, endDate, aboutJob } = Experience;
              return (
                <div className="Experience-Details" key={listId}>
                  <p style={paraStyleCSS}>
                    <i>{startDate} - {endDate}</i>
                  </p>
                  <div>
                    <p style={paraStyleCSS}>
                      <b>{companyName}</b>
                    </p>
                    <p style={paraStyleCSS}>
                      <b>{jobRole}</b>
                    </p>
                    <p style={paraStyleCSS}>
                      {aboutJob}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
