import React from 'react'
import "./Pattern_Style_Resume.css";
import QRCode from 'qrcode.react';

export default function Pattern_Style_Resume({ resumeData }) {
    const { AllSections, isQRCode, liveTempId } = resumeData;
    const liveTemplateURL = `http://localhost:3000/${liveTempId}`;
    const { themeColor } = resumeData;
    const { backgroundPattern } = resumeData.resumeStyle;

    const headingStyleCSS = {
        fontFamily: resumeData.headingTextFont,
        color: resumeData.headingTextColor,
    }

    const paraStyleCSS = {
        fontFamily: resumeData.bodyTextFont,
        color: resumeData.bodyTextColor,
        fontSize: `${resumeData.bodyTextSize}px`
    }

    const combineHeadingStyle = { ...headingStyleCSS, fontSize: `${resumeData.headingTextSize}px` }
    return (
        <div className="pattern-style-resume" style={{ backgroundImage: `url(${backgroundPattern})` }}>
            {
                isQRCode ?
                    <div className="resume-qr-code">
                        <QRCode value={liveTemplateURL} size={'50'} />
                    </div> :
                    <></>
            }

            <div className="header">
                <div className="personal-details">
                    <h1 style={headingStyleCSS}> {resumeData.userName} </h1>
                    <h4 style={headingStyleCSS}> <span className='bottom-theme' style={{ backgroundColor: themeColor }}></span>{resumeData.userJobRole}</h4>
                </div>
                <div
                    className="profile-img"
                    style={{
                        backgroundImage:
                            `url(${resumeData.profileImage})`
                    }}
                ><span className='side-theme' style={{ backgroundColor: themeColor }}></span>
                </div>
            </div>
            <div className="about-information">
                <h2 style={{ ...combineHeadingStyle, borderColor: themeColor }}> {AllSections[0].sectionName}</h2>
                <p style={paraStyleCSS}>{AllSections[0].summary}</p>
            </div>
            <div className="main">
                <div className="left-column">
                    <div className="education-information">
                        <h2 style={{ ...combineHeadingStyle, borderColor: themeColor }}>{AllSections[5].sectionName}</h2>
                        {AllSections[5].list.map(Education => {
                            const { listId, collegeName, course, startDate, endDate, aboutEducation } = Education;
                            return (
                                <div className="education-details" key={listId}>
                                    <div>
                                        <p style={paraStyleCSS}>
                                            <b>{course}</b>
                                        </p>
                                        <p style={paraStyleCSS}>
                                            <b>{collegeName}</b>
                                        </p>
                                        <p style={paraStyleCSS}>
                                            <i>{startDate} - {endDate}</i>
                                        </p>
                                        <p style={paraStyleCSS}>
                                            {aboutEducation}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="skills-information">
                        <h2 style={{ ...combineHeadingStyle, borderColor: themeColor }}>{AllSections[3].sectionName}</h2>
                        <ul>
                            {AllSections[3].list.map(skill => {
                                const { listId, skillName } = skill;
                                return (
                                    <li key={listId}>
                                        <p style={paraStyleCSS}>{skillName}</p>
                                        <span className='skills-side-border' style={{ backgroundColor: themeColor }}></span>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
                <div className="right-column" style={{ borderColor: themeColor }}>
                    <div className="experience-information">
                        <h2 style={{ ...combineHeadingStyle, borderColor: themeColor }}>{AllSections[4].sectionName}</h2>
                        {AllSections[4].list.map(Experience => {
                            const { listId, companyName, jobRole, startDate, endDate, aboutJob } = Experience;
                            return (
                                <div className="experience-details" key={listId}>
                                    <div>
                                        <p style={paraStyleCSS}>
                                            <b>{companyName}</b>
                                        </p>
                                        <p style={paraStyleCSS}>
                                            <b>{jobRole}</b>
                                        </p>
                                        <p style={paraStyleCSS}>
                                            <i>{startDate} - {endDate}</i>
                                        </p>
                                        <p style={paraStyleCSS}>
                                            {aboutJob}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="project-information">
                        <h2 style={{ ...combineHeadingStyle, borderColor: themeColor }}>{AllSections[2].sectionName}</h2>
                        {AllSections[2].list.map(Project => {
                            const { listId, projectName, startDate, endDate, aboutProject } = Project;
                            return (
                                <div className="project-details" key={listId}>
                                    <div>
                                        <p style={paraStyleCSS}>
                                            <b>{projectName}</b>
                                        </p>
                                        <p style={paraStyleCSS}>
                                            <i>{startDate} - {endDate}</i>
                                        </p>
                                        <p style={paraStyleCSS}>
                                            {aboutProject}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="contact-Information" style={{ borderColor: themeColor }}>
                <span className="contact-theme-design" style={{ backgroundColor: themeColor }}></span>
                <ul>
                    {AllSections[1].list.map(element => {
                        const { listId, iconName, contactName } = element
                        return (
                            <li style={paraStyleCSS} key={listId}>
                                <i style={{ backgroundColor: themeColor }}
                                    className={iconName} />
                                {contactName}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>

    )
}
