import React from 'react';
import './Modern_Curriculum_Resume_Templates.css';
import QRCode from 'qrcode.react';

export default function Moder_Curriculum_Resume_Templates({ resumeData }) {

    const { AllSections, isQRCode, liveTempId } = resumeData;
    const liveTemplateURL = `http://localhost:3000/${liveTempId}`;

    const { themeColor } = resumeData;
    const { backgroundPattern } = resumeData.resumeStyle;

    const headingStyleCSS = {
        fontFamily: resumeData.headingTextFont,
        color: resumeData.headingTextColor,
    }

    const paraStyleCSS = {
        fontFamily: resumeData.bodyTextFont,
        color: resumeData.bodyTextColor,
        fontSize: `${resumeData.bodyTextSize}px`
    }

    const combineHeadingStyle = { ...headingStyleCSS, fontSize: `${resumeData.headingTextSize}px`, borderColor: themeColor }

    return (
        <div className="modern-curriculum-resume-templates" style={{ backgroundImage: `url("./background-pattern/${backgroundPattern}.png")` }}>
            {
                isQRCode ? <div className="resume-qr-code">
                    <QRCode value={liveTemplateURL} size={"50"} />
                </div> : <></>
            }
            {/* PERSONAL DETAILS */}
            <div className="personal-details">
                <div className="profile-image" style={{ backgroundImage: `url(${resumeData.profileImage})`, borderColor: themeColor }}></div>
                <div className="name-sec">
                    <h1 style={{ fontFamily: resumeData.headingTextFont }}>{resumeData.userName}</h1>
                    <h4 style={{ fontFamily: resumeData.headingTextFont }}>{resumeData.userJobRole}</h4>

                    <hr style={{ marginBottom: '10px', border: 'none', height: '1px', backgroundColor: 'rgba(255, 255, 255, 0.3)' }} />

                    {/* CONTACT DETAILS */}
                    <ul className="contact-sec">
                        {AllSections[1].list.map(contact => {
                            const { listId, iconName, contactName } = contact;
                            return <li key={listId}><i className={iconName}></i> <p style={{ fontFamily: resumeData.bodyTextFont, fontSize: `${resumeData.bodyTextSize}px` }}>{contactName}</p></li>
                        })}
                    </ul>
                </div>
            </div>

            <div className="column-wrapper">

                {/* LEFT COLUMN */}
                <div className="left-column">

                    {/* SUMMARY */}
                    <div className="common-sec">
                        <h2 style={combineHeadingStyle}>{AllSections[0].sectionName}</h2>
                        <p style={paraStyleCSS}>{AllSections[0].summary}</p>
                    </div>

                    {/* SKILLS */}
                    <div className="skills-sec common-sec">
                        <h2 style={combineHeadingStyle}>{AllSections[3].sectionName}</h2>
                        <ul>
                            {AllSections[3].list.map(skill => {
                                const { listId, skillName } = skill;
                                return <li key={listId} style={paraStyleCSS}>{skillName}</li>
                            })}
                        </ul>
                    </div>

                    {/* PROJECTS */}
                    <div className="project-sec common-sec">
                        <h2 style={combineHeadingStyle}>{AllSections[2].sectionName}</h2>
                        {AllSections[2].list.map(project => {
                            const { listId, projectName, startDate, endDate, aboutProject } = project;
                            return (
                                <div className="each-module" key={listId}>
                                    <p style={paraStyleCSS}><b>{projectName} | <span style={{ color: themeColor }}>{startDate} - {endDate}</span></b></p>
                                    <p style={paraStyleCSS}>{aboutProject}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>

                {/* RIGHT COLUMN */}
                <div className="right-column">

                    {/* JOB EXPERIENCE */}
                    <div className="job-exp-sec common-sec">
                        <h2 style={combineHeadingStyle}>{AllSections[4].sectionName}</h2>
                        {AllSections[4].list.map(jobexp => {
                            const { listId, companyName, jobRole, startDate, endDate, aboutJob } = jobexp;
                            return (
                                <div className="each-module" key={listId}>
                                    <p style={paraStyleCSS}><b>{companyName} | <span style={{ color: themeColor }}>{startDate} - {endDate}</span></b></p>
                                    <p style={{ ...paraStyleCSS, marginBottom: '3px' }}><b>{jobRole}</b></p>
                                    <p style={paraStyleCSS}>{aboutJob}</p>
                                </div>
                            )
                        })}
                    </div>

                    {/* EDUCATION */}
                    <div className="education-sec common-sec">
                        <h2 style={combineHeadingStyle}>{AllSections[5].sectionName}</h2>
                        {AllSections[5].list.map(jobexp => {
                            const { listId, collegeName, course, startDate, endDate, aboutEducation } = jobexp;
                            return (
                                <div className="each-module" key={listId}>
                                    <p style={paraStyleCSS}><b>{collegeName} | <span style={{ color: themeColor }}>{startDate} - {endDate}</span></b></p>
                                    <p style={{ ...paraStyleCSS, marginBottom: '3px' }}><b>{course}</b></p>
                                    <p style={paraStyleCSS}>{aboutEducation}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
