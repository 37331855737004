import React from 'react'
import './CtaAbout.css'

const CtaAbout = () => {
    return (
        <div className="cva-cta">
            <div className="cta-abox">
            <h1>About Us </h1>
            <p>Career Genniew creates standout resumes that highlight your unique skills and achievements, helping you advance your career with professional impact.</p>
            <img src="portfolio.png" alt="" srcset="" className='image1'/>
            <img src="choose.png" alt="" srcset="" className='image3'/>
            <img src="download.png" alt="" srcset="" className='image4'/>
            <img src="resume-writing.png" alt="" srcset="" className='image6'/>
            <img src="document.png" alt="" srcset="" className='image7'/>
            <img src="layout.png" alt="" srcset="" className='image8'/>
            </div>
        </div>
      )
}

export default CtaAbout;